.App {
	font: {
		size: 1em;
		family: 'Nunito Sans', helvetica, arial, sans-serif;
		weight: 600;
	}

	text-align: center;
	margin: 0em auto 2em;
	max-width: 48em;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

header {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin-bottom: 1em;
}

.content {
	font-size: 1.15em;
	line-height: 1.5em;
	// padding: 1.5em 1.75em;
	padding: 1.5em 1.75em 0;
}

article {
	text-align: left;
}

@media screen and (max-width: 520px) {
	.App {
		font-size: 0.85em;
	}
}
