// @import 'fonts';
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,800');

body {
	font-size: 16px;
	color: white;
	background-color: #282c34;
}

a {
	color: #61dafb;
	outline: none;
}

h1 {
	// color: #61dafb;

	font: {
		size: 2.25em;
		weight: 800;
		style: normal;
	}

	text-align: center;
	letter-spacing: -1px;
}

h1:first-child {
	margin-top: 0;
}
